import { graphql, useStaticQuery } from 'gatsby';
import React, { Suspense } from 'react';

import { Box, Container, Stack, ThemeProvider, Typography } from '@mui/material';

import siteTheme from '../shared/theme';
import {
  baseLogoHeight, baseLogoWidth, NavItem as NavItemModel, NavItemGroup as NavItemGroupModel
} from './header';
import NavItemGroup from './nav-item-group';
import { ContentfulAsset, ContentfulAssetImage, NavLink } from './ui';

interface FooterData {
  layoutFooter: {
    id: string
    navItems: NavItemModel[] | NavItemGroupModel[]
    copyright: string
    socialLinks: { id: string; service: string; username: string }[]
    logo: ContentfulAsset
  }
}

const useStyles = () => ({
  background: {
    background:
      "linear-gradient(172deg, rgba(249, 240, 255, 0.47) 64.14%, rgba(226, 241, 255, 0.47) 181.88%)",
    pt: { xs: 4, sm: 4, md: 10 },
    pb: { xs: 12, sm: 12, md: 10 },
    mt: { xs: 4, sm: 4, md: 10 },
  },
})

export default function Footer() {
  const styles = useStyles()

  const data: FooterData = useStaticQuery(graphql`
    query {
      layoutFooter {
        id
        logo {
          url
          alt
          publicURL
          gatsbyImageData(placeholder: NONE, width: 150, height: 47)
        }
        navItems {
          id
          navItemType
          ... on NavItem {
            href
            text
          }
          ... on NavItemGroup {
            name
            navItems {
              id
              href
              text
              description
              icon {
                url
                alt
                publicURL
                gatsbyImageData
              }
            }
          }
        }
        copyright
        socialLinks {
          id
          service
          username
        }
      }
    }
  `)

  const { navItems, copyright } = data.layoutFooter

  return (
    <ThemeProvider theme={siteTheme}>
      <Box component="footer" sx={styles.background}>
        <Container>
          <Stack direction="column" spacing={4}>
            <Stack
              gap={{ xs: 3, sm: 4, md: 8, lg: 14, xl: 20 }}
              direction={{ xs: "column", md: "row" }}
            >
              <Stack
                direction={"column"}
                spacing={2}
                sx={{
                  maxWidth: {
                    xs: "inherit",
                    sm: "inherit",
                    md: "180px",
                    lg: "180px",
                    xl: "180px",
                  },
                }}
              >
                <NavLink to={"/"}>
                  <Box sx={{ display: "none" }}>Home</Box>
                  <ContentfulAssetImage
                    asset={data.layoutFooter.logo}
                    objectFit="cover"
                    imgStyle={{
                      width: baseLogoWidth + "px",
                      height: baseLogoHeight + "px",
                    }}
                  />
                </NavLink>
                <Typography
                  variant="body2"
                  sx={{
                    textAlign: {
                      xs: "center",
                      sm: "center",
                      md: "inherit",
                      lg: "inherit",
                      xl: "inherit",
                    },
                  }}
                >
                  Seeka acknowledges the first nations people of the land we
                  live, work and operate.
                </Typography>
              </Stack>

              <Stack
                direction={"row"}
                spacing={{ xs: 3, sm: 6, md: 10 }}
                useFlexGap
                flexWrap="wrap"
                justifyContent={{
                  xs: "space-between",
                  sm: "space-between",
                  md: "flex-start",
                  lg: "flex-start",
                }}
              >
                {navItems &&
                  navItems.map((navItem) => (
                    <Box key={navItem.id}>
                      {navItem.navItemType === "Group" ? (
                        <NavItemGroup
                          disableDropDown={true}
                          name={navItem.name}
                          navItems={navItem.navItems}
                        />
                      ) : (
                        <NavLink to={navItem.href}>{navItem.text}</NavLink>
                      )}
                    </Box>
                  ))}
              </Stack>
            </Stack>

            <Box sx={{ textAlign: "center" }}>
              <Typography
                variant="body2"
                color="inherit"
                sx={{ marginTop: "20px" }}
              >
                {copyright}
              </Typography>
            </Box>
          </Stack>
        </Container>
      </Box>
    </ThemeProvider>
  )
}
