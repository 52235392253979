export default {
  defaultProps: {
    disableElevation: true,
  },
  styleOverrides: {
    root: ({ ownerState }) => ({
      // ...(ownerState.variant === 'contained' &&
      //   ownerState.color === 'primary' && {
      //   backgroundColor: '#202020',
      //   color: '#fff',
      // }),
      textTransform: 'inherit' as any,
      padding: '0.75rem 1.5rem',
      lineHeight: 'normal',
      fontWeight: 500,
      fontSize: '0.875rem',
      borderRadius: '1.4375rem',
      whiteSpace: 'nowrap' as any
    }),
  }
}
