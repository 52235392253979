module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Seeka","short_name":"Seeka","start_url":"/","background_color":"#ffffff","theme_color":"#722ED1","icon":"src/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"28ce0b78ef5da7fa078674bd3925d871"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":"src/emotion-cache-props"},
    },{
      plugin: require('../node_modules/@seeka-labs/gatsby-plugin-seeka/gatsby-browser.js'),
      options: {"plugins":[],"publicKey":"sdkr_Q2ZESjhPWkNZdzdQRGdSQXU4VWZjV0c3TmdIcFlxTklka2ZJNzIyMjBrZF9iQkZNZUxGOU8xNUNhZUhOT1lacHBLM24zN042X2hPRVctSktkWTVWODRfWUhpUHgtNkJaZ3ZwMDkwaDRkYXRQS3BkaU1aQlE2eS1heVFkWDRfTGpsRk1TZHJiT0dxYlVQdEN6eFlyTld2aXdhZFNyTGZwSkFSaFJCR2tUOWtYZTlyTVM","org":"6b38b47b35f158df06823a0c0c4c6003","id":"0c1426cac8cbdea4dd023a0c0c4c7d52","hosts":[{"hostname":"b467.seeka.co"}]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
