import { Script, ScriptStrategy } from 'gatsby';
import React, { useState } from 'react';

import { CircularProgress } from '@mui/material';

type ChatWidgetProps = {
  onRequestOpen?: () => void
}

export const ChatWidget = ({ onRequestOpen }: ChatWidgetProps) => {
  const [isRequestedByUser, setIsRequestedByUser] = useState(false)

  const isFrontChatAlreadyLoaded =
    typeof window != "undefined" && (window as any).FrontChat !== undefined
  if (isFrontChatAlreadyLoaded) return <></>

  if (isRequestedByUser) {
    return <FrontChatScripts />
  }

  return (
    <ChatIconOverlay
      onClick={() => {
        setIsRequestedByUser(true)
        onRequestOpen && onRequestOpen()
      }}
    />
  )
}

type ChatIconOverlayProps = {
  onClick?: () => void
  loading?: boolean
}

const ChatIconOverlay = ({ onClick, loading }: ChatIconOverlayProps) => {
  const image = loading ? (
    <CircularProgress
      style={{ color: "white", marginLeft: "9px", marginTop: "9px", height: '30px', width: '30px' }}
    />
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" 
      width="24"
      fill="white"
      height="24"
      style={{
        position: "absolute",
        top: "50%",
        marginTop: "-12px",
        left: "50%",
        marginLeft: "-12px",
      }}
      role="button" viewBox="0 0 28 32"><path d="M28 32s-4.714-1.855-8.527-3.34H3.437C1.54 28.66 0 27.026 0 25.013V3.644C0 1.633 1.54 0 3.437 0h21.125c1.898 0 3.437 1.632 3.437 3.645v18.404H28V32zm-4.139-11.982a.88.88 0 00-1.292-.105c-.03.026-3.015 2.681-8.57 2.681-5.486 0-8.517-2.636-8.571-2.684a.88.88 0 00-1.29.107 1.01 1.01 0 00-.219.708.992.992 0 00.318.664c.142.128 3.537 3.15 9.762 3.15 6.226 0 9.621-3.022 9.763-3.15a.992.992 0 00.317-.664 1.01 1.01 0 00-.218-.707z"></path></svg>
  )
  return (
    <div
      id="chat-icon-overlay"
      role="button"
      onClick={onClick}
      tabIndex={0}
      aria-label="open chat support"
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        cursor: "pointer",
        outline: "none",
        border: "none",
        zIndex: 999,
        borderRadius: "32px 32px",
        width: "48px",
        height: "48px",
        backgroundColor: "rgb(114, 46, 209)",
        boxShadow: "0 5px 11px 0 rgba(0, 0, 0, .2)",
      }}
    >
      <div>
        <div
          style={{
            cursor: "pointer",
            outline: "none",
            borderRadius: "32px 32px",
            width: "48px",
            height: "48px",
            backgroundColor: "rgb(114, 46, 209)",
          }}
        >
          {image}
        </div>
      </div>
    </div>
  )
}

const FrontChatScripts = () => {
  const [isChatWidgetLoaded, setIsChatWidgetLoaded] = React.useState(false)
  const [isChatInputLoaded, setIsChatInputLoaded] = React.useState(false)

  return (
    <>
      <Script
        id="intercom-chat-widget"
        strategy={ScriptStrategy.postHydrate}
        onLoad={() => setIsChatWidgetLoaded(true)}
      >
        {`
        (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${process.env.GATSBY_INTERCOM_CHAT_APP_ID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
        `}
      </Script>
      <ChatIconOverlay loading={true} />
      {/* {!isChatInputLoaded ? (
        <ChatIconOverlay loading={true} />
      ) : (
        <></>
      )} */}
      <Script
        id="intercom-chat-widget-init"
        strategy={ScriptStrategy.postHydrate}
        onLoad={() => setIsChatInputLoaded(true)}
      >
        {`
          console.log('booting');        
          function tryOpen(){            
            try {
                //console.log('heree trying to open')
                if(!window.Intercom) return;
                window.Intercom('boot', {
                  app_id: '${process.env.GATSBY_INTERCOM_CHAT_APP_ID}',
                  hide_default_launcher: false,
                });
                window.Intercom('show');
                return true;
            }
            catch(err) {
              console.log(err)
              return false;
            }            
          }
          var timer = setInterval(function() {
            if(tryOpen()){
                clearInterval(timer);
                document.getElementById('chat-icon-overlay').style.display = 'none';
            }
          }, 1000);
          `}
      </Script>
    </>
  )
}
