import React from "react"
import { Slice } from "gatsby"
import Header, { HeaderProps } from "./header"
import Footer from "./footer"

interface LayoutProps {
  children?: React.ReactNode
  header?: HeaderProps
  location?: Location
}

const Layout: React.FC<LayoutProps> = ({ children, location, ...other }) => {
  return (
    <>
      <Header location={location} />
      {/* <ThemeProvider theme={theme}>{children}</ThemeProvider> */}
      {children}

      <Footer />
    </>
  )
}

export default Layout
