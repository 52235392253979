import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import wrapPageElements from './wrap-page-element';
import wrapWithProviders from './wrap-with-providers';

const rIC =
  typeof requestIdleCallback !== "undefined" ? requestIdleCallback : setTimeout

export const onRouteUpdate = ({ location, prevLocation }) => {
  rIC(() => {
    if ((window as any).Intercom) {
      (window as any)?.Intercom('update')
    }
  })
}

export const wrapPageElement = wrapPageElements

export const wrapRootElement = wrapWithProviders
