// import {
//   useDispatch as useAppDispatch,
//   //   useSelector as useAppSelector,
//   //   TypedUseSelectorHook,
// } from 'react-redux';
import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from 'redux-persist';

// import { AnyAction, Middleware, MiddlewareArray, ThunkDispatch, ThunkMiddleware, configureStore } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';

import { appApi } from '../api/redux/app/appApi';
import { rootPersistConfig, rootReducer } from './rootReducer';

// import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
// import { RootState, QueryDefinition, BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta } from '@reduxjs/toolkit/query';

// ----------------------------------------------------------------------

export const createStoreWithoutSet = () => {
  return configureStore({
    reducer: persistReducer(rootPersistConfig, rootReducer),
    preloadedState: {},
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        }
      })
        .concat(appApi.middleware),
  });
}

let createdStore;
// export { store, persistor, dispatch, useSelector, useDispatch };
// let storeDispatch;
export const createStore = () => {
  createdStore = createStoreWithoutSet();

  // const persistor = persistStore(createdStore);

  // type RootState = ReturnType<typeof rootReducer>;

  //type AppDispatch = typeof createdStore.dispatch;

  // storeDispatch = createdStore.dispatch;

  //storeDispatch = () => useAppDispatch<AppDispatch>();

  // const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;

  // store = createdStore;
  return createdStore;
}

// export var dispatch = (action) => storeDispatch(action);