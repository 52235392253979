import { emptySplitApi as api } from "./baseApi"
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    billingListCurrentPlans: build.query<
      BillingListCurrentPlansApiResponse,
      BillingListCurrentPlansApiArg
    >({
      query: () => ({ url: `/api/billing/plan` }),
    }),
    billingGetRecommendedPlan: build.query<
      BillingGetRecommendedPlanApiResponse,
      BillingGetRecommendedPlanApiArg
    >({
      query: (queryArg) => ({
        url: `/api/billing/plan/recommend`,
        params: {
          monthlySessions: queryArg.monthlySessions,
          offering: queryArg.offering,
          frequency: queryArg.frequency,
        },
      }),
    }),
  }),
  overrideExisting: false,
})
export { injectedRtkApi as appApi }
export type BillingListCurrentPlansApiResponse =
  /** status 200  */ ApiResponseDtoOfListResultDtoOfAppBillingPlan
export type BillingListCurrentPlansApiArg = void
export type BillingGetRecommendedPlanApiResponse =
  /** status 200 Will be null if there is no recommended plan */ ApiResponseDtoOfAppBillingPlanWithPrice
export type BillingGetRecommendedPlanApiArg = {
  monthlySessions?: number
  offering?: AppBillingPlanOfferingType
  frequency?: AppBillingPlanPaymentFrequency
}
export type AppBillingPlanOfferingType =
  | "undefined"
  | "serverAndBrowser"
  | "browserOnly"
export type AppBillingPlanPaymentFrequency = "monthly" | "yearly"
export type CurrencyAmount = {
  amount?: number
  /** ISO 4217 currency code */
  currencyCode?: string
  /** Currency symbol */
  currencySymbol?: string
}
export type AppBillingPlanPricing = {
  billingPlanPricingIdentifier?: string
  frequency?: AppBillingPlanPaymentFrequency
  baseCost?: CurrencyAmount
  isUsageMetered?: boolean
  hasNoBaseSessionsIncluded?: boolean
  /** Cost per additional session. Null value indicates no charge for additional sessions.
   */
  costPerAdditionalSession?: CurrencyAmount
}
export type AppBillingPlanEntitlements = {
  /** Allowed monthly sessions. Null value indicates unlimited sessions. */
  monthlySessions?: number | null
}
export type AppBillingPlanFeature = {
  isGranted?: boolean
  featureKey?: string
  description?: string
}
export type AppBillingPlan = {
  description?: string
  billingPlanIdentifier?: string
  isCustomPlan?: boolean
  offering?: AppBillingPlanOfferingType
  pricing?: AppBillingPlanPricing[]
  entitlements?: AppBillingPlanEntitlements
  features?: AppBillingPlanFeature[]
}
export type ListResultDtoOfAppBillingPlan = {
  items?: AppBillingPlan[]
  totalCount?: number
}
export type ApiResponseDtoOfListResultDtoOfAppBillingPlan = {
  result?: ListResultDtoOfAppBillingPlan
}
export type ApiResponseDtoOfObject = {
  result?: any
}
export type ResponseResultType = "undefined" | "success" | "failed"
export type HelpAction = {
  helpActionUrl?: string
  helpActionTitle?: string
}
export type PropertyValidationError = {
  errorKey?: string
  helpActions?: HelpAction[]
  propertyName?: string
  attemptedValue?: any
  errorMessage?: string
  children?: PropertyValidationError[]
}
export type ValidationSummary = {
  properties?: PropertyValidationError[]
}
export type ErrorInfo = {
  message?: string
  code?: number
  correlationId?: string
  validation?: ValidationSummary
}
export type DetailedApiResponseDtoOfObject = ApiResponseDtoOfObject & {
  type?: ResponseResultType
  error?: ErrorInfo
  success?: boolean
}
export type ApiResponseErrorDtoOfObject = DetailedApiResponseDtoOfObject &
  object
export type ApiResponseError = ApiResponseErrorDtoOfObject & object
export type AppBillingPlanPricingCalculation = {
  overageSessionCount?: number | null
  sessionUsagePercent?: number | null
  /** Base plan cost */
  baseCost?: CurrencyAmount
  /** Can be null if no session overage cost */
  sessionOverageCost?: CurrencyAmount
  /** Total cost including base and overage fees */
  totalCost?: CurrencyAmount
}
export type AppBillingPlanWithPrice = {
  plan?: AppBillingPlan
  price?: AppBillingPlanPricingCalculation
}
export type ApiResponseDtoOfAppBillingPlanWithPrice = {
  result?: AppBillingPlanWithPrice
}
export const {
  useBillingListCurrentPlansQuery,
  useBillingGetRecommendedPlanQuery,
} = injectedRtkApi
