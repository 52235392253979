import React from "react"
import { ContentfulAsset } from "./ui"
import NavItemGroupStatic from "./nav-item-group-static"

export type NavItemGroupNavItem = {
  id: string
  href: string
  icon: ContentfulAsset
  text: string
  description?: string
}

export interface NavItemGroupProps {
  name: string
  navItems: NavItemGroupNavItem[]
  disableDropDown?: boolean
}

export default function NavItemGroup({
  disableDropDown,
  ...props
}: NavItemGroupProps) {
  return <NavItemGroupStatic {...props} />
  // return disableDropDown ? (
  //   <NavItemGroupStatic {...props} />
  // ) : (
  //   <NavItemGroupWithDropdown {...props} />
  // )
}
