import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

import { appApi } from '../api/redux/app/appApi';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  [appApi.reducerPath]: appApi.reducer,
});

export { rootPersistConfig, rootReducer };
