import palette from './palette'

export default {
  fontFamily: 'Roboto',
  h1: {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: '3rem',
    fontFamily: 'Roboto',
    lineHeight: "2.5rem",
  },
  h2: {
    color: '#160637',
    fontWeight: 700,
    lineHeight: "2.5rem",
    fontSize: "2rem",
    fontFamily: 'Roboto',
  },
  h3: {
    color: '#160637',
    fontWeight: 700,
    lineHeight: "2.5rem",
    fontSize: "1.5rem",
    fontFamily: 'Roboto',
  },
  h4: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '20px',
    fontFamily: 'Roboto',
  },
  h5: {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: '16px',
  },
  h6: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '14px',
  },
  subtitle1: {
    color: palette.text.primary,
    fontSize: '16px',
    lineHeight: '25px',
  },
  subtitle2: {
    color: palette.text.primary,
    fontSize: '14px',
    letterSpacing: 0,
    lineHeight: '16px',
  },
  body1: {
    color: '#666679',
    fontSize: '1rem',
    lineHeight: "1.5rem",
    fontWeight: 400,
  },
  body2: {
    color: '#666679',
    fontSize: '13px',
    lineHeight: '20px',
  },
  button: {
    color: palette.text.primary,
    fontSize: '14px',
  },
  caption: {
    color: palette.text.secondary,
    fontSize: '12px',
    lineHeight: '16px',
  },
}
