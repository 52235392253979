import { getCookieItem } from "./useCookie";
import isAbsoluteUrl from "is-absolute-url";

export function getPartnerCode(locationSearch: string): string | undefined {
    if (locationSearch && locationSearch.search(/partner/gi)) {
        const params = new URLSearchParams(location.search)
        const partnerCode = params.get("partner")
        if (partnerCode) {
            return partnerCode;
        }
    }

    var cookie = getCookieItem('seeka_partner_key');

    return cookie || undefined;
}

export function getPartnerLink(initialUrl: string | undefined, location: Location | undefined) {
    // if(isAbsoluteUrl(initialUrl)){
    //     if()
    // }

    if (!location) return initialUrl;

    var cookieVal = getPartnerCode(location.search)

    const alreadyAdded = initialUrl && initialUrl.indexOf('partner=') > -1;

    if (!initialUrl || !isAbsoluteUrl(initialUrl) || !cookieVal || alreadyAdded) return initialUrl;

    if (!initialUrl) {
        return `?partner=${cookieVal}`;
    }
    else if (initialUrl.indexOf('?') > -1) {
        return `${initialUrl}&partner=${cookieVal}`;
        // return `${initialUrl}&partner_key=${cookieVal}&parter_prog=${process.env.GATSBY_PARTNERO_PROGRAM_CODE}`;
    }
    else {
        return `${initialUrl}?partner=${cookieVal}`;
        // return `${initialUrl}?partner_key=${cookieVal}&parter_prog=${process.env.GATSBY_PARTNERO_PROGRAM_CODE}`;
    }
}