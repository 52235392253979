import React from 'react';

import { Stack, Typography } from '@mui/material';

import { NavItemGroupProps } from './nav-item-group';
import { NavLink } from './ui';

export default function NavItemGroupStatic({
  name,
  navItems,
}: NavItemGroupProps) {
  return (
    <Stack gap={{ xs: 0, md: 2 }}>
      <Typography sx={{ fontSize: "1rem", fontWeight: 700, color: "#160637" }}>
        {name}
      </Typography>

      <Stack
        direction="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        gap={{ xs: 0, md: 2 }}
      >
        {navItems.map((navItem) => {
          const isCookieConsentButton = navItem.href === '#consent-settings';

          // ? <Button sx={{ ...styles.cookieSettingsButton }} LinkComponent={Button} variant="text" id="ot-sdk-btn" className="ot-sdk-show-settings">{navItem.text}</Button> : <NavLink to={navItem.href}>{navItem.text}</NavLink>

          return (
            <NavLink
              key={navItem.id}
              to={isCookieConsentButton ? undefined : navItem.href}
              onClick={isCookieConsentButton ? () => { (window as any).OneTrust?.ToggleInfoDisplay() } : undefined}
              sx={{
                p: "2px",
                color: "inherit",
                m: 0,
                width: { xs: "100%", sm: "100%", md: "inherit" }, // SEO
                minHeight: { xs: "48px", sm: "48px", md: "inherit" }, // SEO
                fontSize: "0.85rem!important",
                textAlign: "left",
                minWidth: "0px",
                borderRadius: "2px",
                justifyContent: "left",
              }}
            >
              {navItem.text}
            </NavLink>
          )
        })}
      </Stack>
    </Stack>
  )
}
