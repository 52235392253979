import queryString from 'query-string';


import {
  buildCreateApi,
  coreModule,
  reactHooksModule,
  fetchBaseQuery
} from '@reduxjs/toolkit/query/react'

const createApi = buildCreateApi(
  coreModule(),
  reactHooksModule({ unstable__sideEffectsInRender: true })
)

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptySplitApi = createApi({
  reducerPath: 'appApi',
  baseQuery: fetchBaseQuery(
    {
      baseUrl: process.env.GATSBY_SEEKA_BACKEND_API_URL,
      prepareHeaders(headers, api) {
        return headers;
      },
      paramsSerializer(params) {
        return queryString.stringify(params);
      },
    }),
  endpoints: () => ({}),
})