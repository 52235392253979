import { createTheme } from "@mui/material"
import palette from "./palette"
import typography from "./typography"
import shape from "./shape"
import overrides from "./overrides"

interface BrandObject {
  backgroundColor: string
  color: string
}
interface ConvergeObject {
  backgroundColor: string
  color: string
}
interface ConvergeDestinationObject {
  backgroundColor: string
  color: string
}
interface Gradient {
  blue: string
  red: string
  purple: string
}
export interface Brand {
  facebook: BrandObject
  google: BrandObject
  sms: BrandObject
  email: BrandObject
  omnichannel: BrandObject
  wallet: BrandObject
  messenger: BrandObject
  ticketrev: BrandObject
  manychat: BrandObject
  zenGlobal: BrandObject
  webhook: BrandObject
  wait: BrandObject
  goal: BrandObject
  stage: BrandObject
  note: BrandObject
  conditionalSplit: BrandObject
  oneTimeCampaign: BrandObject
  automatedCampaign: BrandObject
  enrichmentCampaign: BrandObject
  acquisitionCampaign: BrandObject
  outboundDirection: BrandObject
  inboundDirection: BrandObject
  omniDirection: BrandObject
}

export interface Converge {
  shopify: ConvergeObject
  woocommerce: ConvergeObject
  zapier: ConvergeObject
  bigcommerce: ConvergeObject
  wordpress: ConvergeObject
  scriptTag: ConvergeObject
}

export interface ConvergeDestination {
  facebook: ConvergeDestinationObject
  googleAds: ConvergeDestinationObject
  googleAnalytics: ConvergeDestinationObject
  snapchat: ConvergeDestinationObject
  pinterest: ConvergeDestinationObject
  tiktok: ConvergeDestinationObject
}

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    warning: PaletteColor
    success: PaletteColor
    info: PaletteColor
    gradient: Gradient
    brand: Brand
    converge: Converge
    convergeDestination: ConvergeDestination
    border: string
    divider: string
  }
  interface PaletteOptions {
    warning?: PaletteColorOptions
    success?: PaletteColorOptions
    info?: PaletteColorOptions
  }
}

/* declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    border: string
    divider: string
  }
  interface ThemeOptions {
    border?: string
    divider?: string
  }
} */

// defaults - https://mui.com/material-ui/customization/breakpoints/
export const breakpointValues = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
}

const theme = createTheme({
  palette,
  typography,
  components: {
    ...overrides,
  },
  shape,
  breakpoints: {
    values: breakpointValues,
  },
})

theme.typography.h1 = {
  [theme.breakpoints.only("xl")]: {
    fontSize: "2.6rem",
    lineHeight: "2.8rem",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "1.8rem",
    // lineHeight: "1.1rem",
  },
}

theme.typography.h2 = {
  [theme.breakpoints.only("xl")]: {
    fontSize: "1.8rem",
    lineHeight: "2.1rem",
    // lineHeight: "1rem",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "1.5rem",
  },
}

theme.typography.h3 = {
  [theme.breakpoints.only("xl")]: {
    fontSize: "1.4rem",
    fontWeight: 500,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "1.3rem",
  },
}

export default theme
