import React, { useEffect } from "react"
import { ChatWidget } from "./src/components/chatWidget"
import Layout from "./src/components/layout"
import { setCookieItem } from "./src/shared/hooks/useCookie";

export default ({ element, props }) => {
  const { location } = props;

  useEffect(() => {
    if (location.search && location.search.search(/partner/gi)) {
      const params = new URLSearchParams(location.search)
      const partnerCode = params.get("partner")
      if (partnerCode) {
        setCookieItem("seeka_partner_key", partnerCode, 30)
      }
    }
  }, [location.search])

  return (
    <Layout {...props}>
      {element}
      <ChatWidget />
    </Layout>
  )
}
