export const getCookieItem = (key: string): string | undefined => {
    if (typeof document !== 'undefined') {
        return document.cookie.split(`; `).reduce((total, currentCookie) => {
            const item = currentCookie.split(`=`);
            const storedKey = item[0];
            const storedValue = item[1];

            return key === storedKey ? decodeURIComponent(storedValue) : total;
        }, ``);
    }

    return undefined;
};

export const setCookieItem = (key: string, value: string, numberOfDays: number) => {
    const now = new Date();
    if (!value) return;

    // set the time to be now + numberOfDays
    now.setTime(now.getTime() + numberOfDays * 60 * 60 * 24 * 1000);
    if (typeof document !== 'undefined') {
        document.cookie = `${key}=${value}; expires=${now.toUTCString()}; path=/`;
    }
};
