import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';

import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';

import { createStore } from './src/shared/redux/store';
import theme from './src/shared/theme';

export default ({ element }) => {
  const store = createStore()

  return (
    <ReduxProvider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        {element}
      </ThemeProvider>
    </ReduxProvider>
  )
}
