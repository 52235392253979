import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Close, Menu } from '@mui/icons-material';
import {
  AppBar, Box, Button, Container, GlobalStyles, IconButton, Stack, Theme, ThemeProvider, Toolbar,
  useTheme
} from '@mui/material';
import { CrossDomainLink } from '@seeka-labs/converge-react';

import siteTheme from '../shared/theme';
import NavItemGroup, { NavItemGroupNavItem } from './nav-item-group';
import { ContentfulAsset, ContentfulAssetImage, NavLink } from './ui';
import { getPartnerLink } from '../shared/hooks/usePartnerCodeLink';

export const mobileNavLinkStyle = (muiTheme: Theme) => ({
  display: "block",
  color: "white",
  zIndex: 1,
  fontSize: muiTheme.typography.h3.fontSize,
  paddingTop: muiTheme.spacing(1),
  paddingBottom: muiTheme.spacing(2),
  paddingLeft: muiTheme.spacing(4),
  paddingRight: muiTheme.spacing(4),
})

export const mobileAppFloatingMenuStyle = (muiTheme: Theme) => ({
  color: "white",
  zIndex: 999,
  fontSize: muiTheme.typography.h3.fontSize,
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  pt: 12,
  pl: 1,
  position: "absolute",
  background: "linear-gradient(180deg, #1E0C42 50%, #6C60C0 200%)",
})

export const desktopAppBarStyle = (theme: Theme) => ({
  background: "transparent",
  pt: 2,
  display: {
    xs: "none",
    sm: "none",
    md: "none",
    lg: "inherit",
    xl: "inherit",
  },
  // width: "100%",
  // top: 0,
  // left: 0,
  // right: 0,
})

export const mobileAppBarStyle = (theme: Theme) => ({
  background: "transparent",
  pt: 1,
  pr: 1,
  pl: 0,
  display: {
    xs: "inherit",
    sm: "inherit",
    md: "inherit",
    lg: "none",
    xl: "none",
  },
  // width: "100%",
  // top: 0,
  // left: 0,
  // right: 0,
})

export type NavItem = {
  id: string
  navItemType: "Link"
  href: string
  text: string
}

export type NavItemGroup = {
  id: string
  navItemType: "Group"
  name: string
  navItems: NavItemGroupNavItem[]
}

interface HeaderData {
  layoutHeader: {
    id: string
    navItems: NavItem[] | NavItemGroup[]
    logo: ContentfulAsset
    cta: {
      id: string
      href: string
      text: string
    }
  }
}

export type HeaderProps = {
  darkForeground?: boolean
  location: Location
}
export const baseLogoHeight = 47
export const baseLogoWidth = 150

export default function Header({ darkForeground, location }: HeaderProps) {
  const data: HeaderData = useStaticQuery(graphql`
    query {
      layoutHeader {
        id
        logo {
          url
          alt
          publicURL
          gatsbyImageData(placeholder: NONE, width: 150, height: 47)
        }
        navItems {
          id
          navItemType
          ... on NavItem {
            href
            text
          }
          ... on NavItemGroup {
            name
            navItems {
              id
              href
              text
              description
              icon {
                url
                alt
                publicURL
                gatsbyImageData
              }
            }
          }
        }
        cta {
          id
          href
          text
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={siteTheme}>
      <GlobalStyles styles={(theme) => ({
        '#onetrust-pc-sdk button': {
          borderRadius: `20px!important`
        }
      })} />

      <MobileHeader darkForeground={darkForeground} location={location} {...data} />
      <DesktopHeader darkForeground={darkForeground} location={location} {...data} />
    </ThemeProvider>
  )
}

type HeaderComponentProps = HeaderData & {
  darkForeground: boolean
  location: Location
}

const DesktopHeader = ({ darkForeground, location, ...props }: HeaderComponentProps) => {
  const { navItems, cta, logo } = props.layoutHeader
  const theme = useTheme()


  const appBarStyle = desktopAppBarStyle(theme)

  return (
    <AppBar position="absolute" elevation={0} sx={appBarStyle}>
      <Toolbar>
        <Container>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems="center"
          >
            <NavLink to="/">
              <Box sx={{ display: "none" }}>Home</Box>
              <ContentfulAssetImage
                asset={logo}
                loading="eager"
                imgStyle={{
                  width: baseLogoWidth + "px",
                  height: baseLogoHeight + "px",
                }}
              />
            </NavLink>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems="center"
              gap={{ md: 2, lg: 4 }}
            >
              <nav>
                <Stack
                  gap={{ md: 1, lg: 2 }}
                  direction={"row"}
                  justifyContent="space-evenly"
                  alignItems="center"
                >
                  {navItems &&
                    navItems.map((navItem) => (
                      <div key={navItem.id}>
                        {navItem.navItemType === "Group" ? (
                          <NavItemGroup
                            name={navItem.name}
                            navItems={navItem.navItems}
                          />
                        ) : (
                          <NavLink
                            to={navItem.href}
                            sx={
                              !darkForeground
                                ? {
                                  color: "white",
                                  "&:hover": {
                                    color: theme.palette.grey[400],
                                  },
                                }
                                : {}
                            }
                          >
                            {navItem.text}
                          </NavLink>
                        )}
                      </div>
                    ))}
                </Stack>
              </nav>
              <div>
                {cta && (
                  <CrossDomainLink>
                    <Button
                      href={getPartnerLink(cta.href, location)}
                      variant="contained"
                      target="_blank"
                      disableElevation
                      sx={
                        !darkForeground
                          ? {
                            color: theme.palette.primary.main,
                            borderColor: "white",
                            background: "white",
                            "&:hover": {
                              backgroundColor: theme.palette.grey[400],
                            },
                          }
                          : {}
                      }
                    >
                      {cta.text}
                    </Button>
                  </CrossDomainLink>
                )}
              </div>
            </Stack>
          </Stack>
        </Container>
      </Toolbar>
    </AppBar>
  )
}

const MobileHeader = ({ darkForeground, location, ...props }: HeaderComponentProps) => {
  const { navItems, cta, logo } = props.layoutHeader

  const theme = useTheme()

  const appBarStyle = mobileAppBarStyle(theme)
  const openMenuStyle = mobileAppFloatingMenuStyle(theme)
  const mobileLinkStyling = mobileNavLinkStyle(theme)
  const [isOpen, setOpen] = React.useState(false)

  React.useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"
      document.documentElement.style.overflow = "hidden"
    } else {
      document.body.style.overflow = ""
      document.documentElement.style.overflow = ""
    }
  }, [isOpen])

  return (
    <>
      <AppBar position="absolute" elevation={0} sx={appBarStyle}>
        <Toolbar sx={{ p: 0 }}>
          <Stack
            direction="row"
            alignItems="center"
            sx={{ width: "100%" }}
            justifyContent={"space-between"}
          >
            <span>
              <NavLink to="/" onClick={() => setOpen(false)}>
                <Box sx={{ display: "none" }}>Home</Box>
                <Box
                  sx={{
                    height: {
                      xs: baseLogoHeight * 0.6 + "px",
                      sm: baseLogoHeight + "px",
                    },
                    width: {
                      xs: baseLogoWidth * 0.6 + "px",
                      sm: baseLogoWidth + "px",
                    },
                  }}
                >
                  <ContentfulAssetImage
                    asset={logo}
                    loading="eager"
                    objectFit="cover"
                  />
                </Box>
              </NavLink>
            </span>

            <Stack
              direction="row"
              alignItems="center"
              gap={2}
              justifyContent={"flex-end"}
            >
              {!isOpen && cta && (
                <CrossDomainLink>
                  <Button
                    href={getPartnerLink(cta.href, location)}
                    size="small"
                    target="_blank"
                    variant={isOpen ? "outlined" : "contained"}
                    sx={{
                      color: "white",
                      borderColor: "white",
                      fontSize: { xs: "0.7rem", sm: "1rem" },
                      p: { xs: "7px 12px", sm: "10px 16px" },
                      // marginRight: isOpen ? "18px" : null,
                    }}
                  >
                    {cta.text}
                  </Button>
                </CrossDomainLink>
              )}
              <div>
                <IconButton
                  title="Toggle menu"
                  onClick={() => setOpen(!isOpen)}
                >
                  {isOpen ? (
                    <Close sx={{ color: "white" }} />
                  ) : (
                    <Menu sx={{ color: "white" }} />
                  )}
                </IconButton>
              </div>
            </Stack>
          </Stack>
        </Toolbar>
      </AppBar>
      {isOpen && (
        <Box sx={openMenuStyle}>
          <nav>
            <Stack>
              {navItems?.map((navItem) =>
                navItem.navItemType === "Group" ? (
                  <NavItemGroup
                    key={navItem.id}
                    name={navItem.name}
                    navItems={navItem.navItems}
                  />
                ) : (
                  <NavLink
                    key={navItem.id}
                    to={navItem.href}
                    onClick={() => setOpen(false)}
                    style={mobileLinkStyling}
                  >
                    {navItem.text}
                  </NavLink>
                ),
              )}
              {cta && (
                <Button
                  href={cta.href}
                  size="small"
                  target="_blank"
                  variant={"contained"}
                  sx={{
                    color: "white",
                    borderColor: "white",
                    fontSize: "1rem",
                    p: "10px 16px",
                    mt: "20px",
                    ml: "20px",
                    mr: "25px",
                    // marginRight: isOpen ? "18px" : null,
                  }}
                >
                  {cta.text}
                </Button>
              )}
            </Stack>
          </nav>
        </Box>
      )}
    </>
  )
}
